import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { Fade } from "react-awesome-reveal";
import styled from "styled-components";
import { toolLinks } from "../footer/linkfooter";
import { footer } from "../../data/personal";

export const Item = styled.div`
  display: inline-block;
  margin: 10px;
  border-radius: 50%;
`;

const Footer = () => {
  return (
    <Box
      as="footer"
      pt="4"
      pb="4"
      bottom="0"
      position="relative"
      textAlign="center"
    >
      <Text
        letterSpacing="-0.001rem"
        lineHeight="-0.001rem"
        fontSize={{ base: "md", md: "lg" }}
        fontWeight="medium"
      >
        {footer.name}
      </Text>
      <Flex justify="center" mt="2" alignItems="center">
      <Text
        letterSpacing="-0.001rem"
        lineHeight="-0.001rem"
        fontSize={{ base: "xs", md: "s" }}
        fontWeight="medium"
      >
        Build with
      </Text>
        <Fade Fade direction="up" triggerOnce>
          {toolLinks.map((link, index) => (
            <Item>
              <Fade delay={index * 200} triggerOnce={true}>
                <a
                  href={link.href}
                  target={link.target}
                  rel={link.rel}
                  className="social-icon"
                >
                  <link.icon className="icon" size={24} />
                </a>
              </Fade>
            </Item>
          ))}
        </Fade>
      </Flex>
      <Text
        letterSpacing="-0.001rem"
        lineHeight="-0.001rem"
        fontSize={{ base: "s", md: "md" }}
        fontWeight="medium"
      >
        {footer.copywright} &copy;
      </Text>
    </Box>
  );
};

export default Footer;
