import logoEfo from '../assets/images/logoEfo.png';
import logoInfisity from '../assets/images/logoInfisity.png'
import logoMaos from '../assets/images/logoMaos.png'
import logoSade from '../assets/images/logoSade.png'
import logoTechno from '../assets/images/logoTechno.png'	


export const Bio = {
    name : "Hubertus Rino Augenio",
    roles : [
        "Software QA", 
        "Software Engineer",
        "Frontend Developer",
        "Data Analyst"
    ],
    desc : "I'm Currently a FreshGraduate with experienced in Software Quality Control and Web Development. I have strong communication skills, and deep understanding of application analysis and development processes",
    socialLinks : {
        email : "mailto:hubertusrino@gmail.com",
        github : "https://github.com/rinogen",
        linkedin: "https://www.linkedin.com/in/hubertusrino/",
        instagram : "https://instagram.com/rinoaugenio",
        resume : "https://drive.google.com/file/d/1qXflOB5_ZF5oM5mCeIBp8vDctRCdmoKb/view?usp=sharing"
    }
    
}

export const experiences = [
        {
            id : 0,
            company : "PT Sentosa Fishery Indonesia ",
            image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxX5jbg9eD28iFI3UyOT38dbHzX8sAR81KJtNMm1Jla7tnxlHaZHzcmpU2mR36AuABhbc&usqp=CAU",
            position : "Co-Founder and Digital Operation ",
            desc :[
            "As one of the founders, I am involved in helping build and develop companies operating in the fisheries sector.", "Manage and build digital company assets which include landing pages, online sales platforms via e-commerce, and are tasked collecting B2B customer location and contact information through scraping on Google Maps pages."], 
            date : "August 2021 – December 2023"
        },
        {
            id : 1,
            company : "Astra Credit Companies ",
            image : "https://media.licdn.com/dms/image/D560BAQEZ4Aczwfm4GQ/company-logo_200_200/0/1719257216950/astra_credit_companies_acc__logo?e=2147483647&v=beta&t=U7-sX4Ek9rLSuor2ZlalH_I6jp_L5fv4EgGP3hfPH4w",
            position : "Software Quality Control Intern ",
            desc : [" Develop test plans, perform testing, identify and report bugs, conduct analysis to understand the causes of problems and provide recommendations for improvements.","Record and report test results in SIT and UAT documents to the project leader. Develop scripts and carry out automation testing on web, mobile and API using Katalon Studio and its integration with Appium and Selenium."],
            date : "August 2022 – January 2023"
        },
        {
            id : 2,
            company : "Astra Credit Companies",
            position : "Software Quality Control Bootcamp",
            image : "https://media.licdn.com/dms/image/D560BAQEZ4Aczwfm4GQ/company-logo_200_200/0/1719257216950/astra_credit_companies_acc__logo?e=2147483647&v=beta&t=U7-sX4Ek9rLSuor2ZlalH_I6jp_L5fv4EgGP3hfPH4w",
            desc : [" Learn about software testing, create test cases that cover several test scenarios, use Katalon Studio for automated testing (Mobile, Web, API). Learn Postman to perform API testing, use of JMeter in performance testing."," Document testing into SIT and UAT documents. , look for bugs and report them to the team, determine the priority of bugs that occur, and perform regression tests."],
            date : "January 2022 – June 2022"
        }
]

export const skills = [
    {
        title : "~Software Testing~",
        skills : [
                        {
                name : "Katalon",
                image : "https://d1dykungmee0hk.cloudfront.net/wp-content/uploads/2024/05/Logo-1_1.png"
            },
            {
                name : "Postmant",
                image : "https://www.svgrepo.com/show/354202/postman-icon.svg"
            },
            {
                name : "ClickUp",
                image : "https://www.svgrepo.com/show/331339/clickup.svg"
            }
            ,
            {
                name : "Trello",
                image : "https://www.svgrepo.com/show/475688/trello-color.svg"
            },
            {
                name : "Selenium",
                image : "https://www.svgrepo.com/show/354321/selenium.svg"
            },
            {
                name : "Jira",
                image : "https://www.svgrepo.com/show/353935/jira.svg"
            }
        ]
    },
    {
        title : "~Frontend Developer~",
        skills: [
            {
                name : "HTML",
                image : "https://www.svgrepo.com/show/452228/html-5.svg"
            },
            {
                name : "CSS",
                image : "https://www.svgrepo.com/show/373535/css.svg"
            },
            {
                name : "JavaScript",
                image : "https://www.svgrepo.com/show/349419/javascript.svg"
            },
            {
                name : "React",
                image : "https://www.svgrepo.com/show/452092/react.svg"
            },
            {
                name : "Bootstrap",
                image : "https://www.svgrepo.com/show/353498/bootstrap.svg"
            },
            {
                name : "TypeScript",
                image : "https://www.svgrepo.com/show/349540/typescript.svg"
            }
        ]
    },

    {
        title : "~Backend Developer~",
        skills: [
            {
                name : "MySQL",
                image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJqU8iicsor7d9NFNKIYuL7kICI_lcaU5dff2HqDISwK6P2V2RQ_weZUzIMzsmkEPPU6g&usqp=CAU"
            },
            {
                name : "MonggoDB",
                image : "https://lh3.googleusercontent.com/2eFFXuppR-nDBD8_GVe06RxdPiCBCpGQSEoB3PuVrNqiclbEs_JpJQDrWNBoBCC5y98_VP1r74gHgIvuxyriYg"
            },
            {
                name : "Express JS",
                image : "https://ajeetchaulagain.com/static/7cb4af597964b0911fe71cb2f8148d64/87351/express-js.png"
            },
            {
                name : "Firebase",
                image : "https://www.svgrepo.com/show/373595/firebase.svg"
            },
            {
                name : "Node JS",
                image : "https://www.svgrepo.com/show/303658/nodejs-1-logo.svg"
            },
         
        ]
    },
    {
        title : "~Data Analyst~",
        skills: [
            {
                name : "Python",
                image : "https://www.svgrepo.com/show/452091/python.svg"
            },

            {
                name : "Pandas",
                image : "https://www.svgrepo.com/show/473742/pandas.svg"
            },

            {
                name : "Mathplotlib",
                image : "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Created_with_Matplotlib-logo.svg/2048px-Created_with_Matplotlib-logo.svg.png"
            },

            {
                name : "Numpy",
                image : "https://www.svgrepo.com/show/373938/numpy.svg"
            },
            {
                name : "Microsoft Excel",
                image : "https://www.svgrepo.com/show/373589/excel.svg"
            },
            {
                name : "Seaborn",
                image : "https://avatars.githubusercontent.com/u/22799945?s=200&v=4"
            },
           
         
        ]
    },
    {
        title : "~Other~",
        skills: [
            {
                name : "Github",
                image : "https://www.svgrepo.com/show/512317/github-142.svg"
            },

            {
                name : "Figma",
                image : "https://www.svgrepo.com/show/452202/figma.svg"
            },

            {
                name : "Adobe Premier",
                image : "https://www.svgrepo.com/show/452150/adobe-premiere.svg"
            },

            {
                name : "Adobe Illustrator",
                image : "https://www.svgrepo.com/show/452147/adobe-illustrator.svg"
            },
            {
                name : "Visual Studio",
                image : "https://www.svgrepo.com/show/354522/visual-studio-code.svg"
            }
           
         
        ]
    }
]

export const projects = [
    {
        name : "Infisity",
        img: logoInfisity,
        desc : "Fisheries database information system compiled from survey results and interviews with fisheries cultivators and processors in the Bantul Regency and Yogyakarta City areas.",
        date : "September 2023 – October 2023",
        link :"https://dramatic-duck-2b0.notion.site/Infisity-0087e7cbfa734450b2b3d1f0b5f082eb"
        
    },
    {
        name : "Befast",
        img: logoTechno,
        desc : "ACC beFast  is designed to support users and employees of Berijalan Astra Credit Company in booking rooms, vehicles, assets, and manpower efficiently. This application is in the form of a website and mobile.",
        date : "August 2022 – January 2023",
        link : "https://dramatic-duck-2b0.notion.site/BeFast-af468f4df8ff4718afe1f52bddf59aa8?pvs=4"
    },
    {
        name : "RRR",
        img: logoTechno,
        desc : "RRR is internal ACC application that designed to record, verify, and book units resulting from Recovery processes, eliminating duplicate entries and assisting in the daily national Recovery recording process.  ",
        date : "January 2022 – June 2022",
        link : "https://dramatic-duck-2b0.notion.site/RRR-4a653e5963b8449d8920a0a59c30d02a?pvs=4"
    },
    {
        name : "EFO",
        img: logoEfo,
        desc : " Microparticle filtration tool and Internet of Things-based fish farming pond water quality monitoring tool integrated with ThingSpeak. Plan a infographic poster designs and video demonstrations of the application. ",
        date : "June 2021 – September 2021",
        link : "https://dramatic-duck-2b0.notion.site/EFO-22da1d39d36642ee8a212bc57dcbc420?pvs=4"
    },
    {
        name : "Maos",
        img: logoMaos,
        desc : "Book lending application that facilitates the exchange of physical books among users. Designed to foster a community of book enthusiasts, this application allows users to lend and borrow books directly, creating a seamless and user-friendly experience for book sharing.",
        date : "February 2021 – March 2021",
        link : "https://dramatic-duck-2b0.notion.site/Maos-18ba9078f8884ab0a593b69458106104?pvs=4"
    },
    {
        name : "Sade",
        img : logoSade,
        desc : "Regional-based MSME buying and selling applications at regional, sub-district and sub-district levels so that it makes it easier for customers to find out and search for shops and services around them.",
        date : "December 2020 – January 2021",
        link : "https://dramatic-duck-2b0.notion.site/Sade-6596b9d2e1af4a3eb90cfc3bf510d338?pvs=4"
    },
    ]

export const footer = {
    name : "Hubertus Rino Augenio",
    copywright : "All rights reserved.",
    tool : {
        react : "https://react.dev/",
        chakra : "https://v2.chakra-ui.com/",
        github: "https://github.com/rinogen",
        firebase:"https://firebase.google.com/"
    }
}
