import { SiChakraui,SiPreact,SiGithub, SiFirebase } from "react-icons/si";
import {footer} from '../../data/personal';

export const toolLinks = [
    {
        icon: SiPreact,
        href: footer.tool.react,
        target: "_blank",
        rel: "noreferrer",
    },
    {
        icon: SiChakraui,
        href: footer.tool.chakra,
        target: "_blank",
        rel: "noreferrer",
    },
    {
      icon: SiGithub,
      href: footer.tool.github,
      target: "_blank",
      rel: "noreferrer",
    },
    {
      icon: SiFirebase,
      href: footer.tool.firebase,
      target: "_blank",
      rel: "noreferrer",
    }
  ]