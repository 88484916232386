import { FaGithub, FaLinkedin, FaInstagram,FaFile } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import {Bio} from '../../data/personal';

export const socialLinks = [
    {
        icon: FaLinkedin,
        href: Bio.socialLinks.linkedin,
        target: "_blank",
        rel: "noreferrer",
    },
    {
        icon: FaFile,
        href: Bio.socialLinks.resume,
        target: "_blank",
        rel: "noreferrer",
    },
    {
      icon: MdEmail,
      href: Bio.socialLinks.email,
      target: "_blank",
      rel: "noreferrer",
    },
    {
        icon: FaGithub,
        href: Bio.socialLinks.github,
        target: "_blank",
        rel: "noreferrer",
    },
    {
        icon: FaInstagram,
        href: Bio.socialLinks.instagram,
        target: "_blank",
        rel: "noreferrer",
    }
  ]