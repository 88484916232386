import React from "react";
import {
  Box,
  Flex,
  useColorMode,
  IconButton,
  Button,
  useDisclosure,
  Spacer,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Divider,
} from "@chakra-ui/react";
import { FaHamburger, FaMoon, FaSun } from "react-icons/fa";
import useSound from "use-sound";
import SoundClick from "../../assets/sound/pop.mp3";
import { Fade } from "react-awesome-reveal";

function Header(props) {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [play] = useSound(SoundClick);

  const changeDarkMode = () => {
    play();
    toggleColorMode();
  };

  return (
    <>
      <Flex
        as="header"
        align="center"
        position="fixed"
        zIndex="9999"
        padding="1.5rem"
        width="full"
        bg={colorMode === "light" ? "white" : "gray.800"}
        color={colorMode === "light" ? "black" : "white"}
        {...props}
      >
        <Flex align="center" justifyContent="center" width="full">
          <Fade cascade damping={0.1} triggerOnce={true}>
            <Box
              display={{ base: "none", md: "flex" }}
              mt={{ base: 4, md: 0 }}
              pl="12"
            >
              <Button
                mt={{ base: 5, md: 0 }}
                mr={6}
                display="block"
                size="md"
                backgroundColor="transparent"
              >
                <a href="#about">/about</a>
              </Button>
              <Button
                mt={{ base: 5, md: 0 }}
                mr={6}
                display="block"
                size="md"
                backgroundColor="transparent"
              >
                <a href="#skills">/skills</a>
              </Button>
              <Button
                mt={{ base: 5, md: 0 }}
                mr={6}
                display="block"
                size="md"
                backgroundColor="transparent"
              >
                <a href="#experiences">/experience</a>
              </Button>
              <Button
                mt={{ base: 5, md: 0 }}
                mr={6}
                display="block"
                size="md"
                backgroundColor="transparent"
              >
                <a href="#project">/project</a>
              </Button>
            </Box>
          </Fade>
        </Flex>

        <Flex display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display={{ base: "none", md: "flex" }}
            mt={{ base: 4, md: 0 }}
            alignContent="center"
          >
            <IconButton
              size="xs"
              bg="transparent"
              icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
              onClick={changeDarkMode}
              aria-label="button"
            />
          </Box>
          <Box display={{ base: "flex", md: "none", lg: "none" }}>
            <IconButton
              size="xs"
              bg="transparent"
              icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
              onClick={changeDarkMode}
              aria-label="button"
            />
            <Button size="xs" bg="transparent" onClick={onOpen}>
              <FaHamburger />
            </Button>
          </Box>
        </Flex>
      </Flex>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size="xs"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex align="center" width="full" wrap="wrap" heigth="full">
              <Box
                as="button"
                textAlign="left"
                w="full"
                display="flex"
                h="min-content"
                paddingY="1"
              >
                <Text fontSize="xs" paddingX="1" fontWeight="bold">
                  {" "}
                  <a href="#about">/about</a>{" "}
                </Text>
              </Box>
              <Divider />
              <Box
                as="button"
                textAlign="left"
                w="full"
                display="flex"
                h="min-content"
                paddingY="1"
              >
                <Text fontSize="xs" paddingX="1" fontWeight="bold">
                  <a href="#skills">/skills</a>{" "}
                </Text>
              </Box>
              <Spacer />
              <Divider />
              <Box
                as="button"
                textAlign="left"
                w="full"
                display="flex"
                h="min-content"
                paddingY="1"
              >
                <Text fontSize="xs" paddingX="1" fontWeight="bold">
                  <a href="#experiences">/experience</a>{" "}
                </Text>
              </Box>
              <Spacer />
              <Divider />
              <Box
                as="button"
                textAlign="left"
                w="full"
                display="flex"
                h="min-content"
                paddingY="1"
              >
                <Text fontSize="xs" paddingX="1" fontWeight="bold">
                  <a href="#project">/project</a>{" "}
                </Text>
              </Box>
              <Spacer />
              <Divider />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Header;
