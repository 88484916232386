import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FaFolder } from "react-icons/fa6";
import { HiDotsHorizontal } from "react-icons/hi";
import { projects } from "../data/personal";
import { Fade } from "react-awesome-reveal";
import ChakraCarousel from "../components/carousel/carousel";

const Project = () => {
  const bgColor = useColorModeValue("gray.100", "white");

  return (
    <Container
      id="project"
      maxW="8xl"
      paddingTop={{ base: "7", md: "20", lg: "20" }}
      paddingLeft={{ base: "7", md: "20", lg: "20" }}
      paddingRight={{ base: "7", md: "20", lg: "20" }}
      paddingBottom={{ base: "20" }}
    >
      <Box mt={{ base: "38", md: "18", lg: "10" }} textAlign="center">
        <Heading
          as="h1"
          letterSpacing={"-.0.001rem"}
          lineHeight={"-.0.001rem"}
          fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
        >
          ~/My Project
        </Heading>
        <Text
          letterSpacing={"-.0.001rem"}
          lineHeight={"-.0.001rem"}
          fontSize={{ base: "md", md: "lg", lg: "xl" }}
        >
          Here are some of my project, please take a look
        </Text>
      </Box>
      <ChakraCarousel gap={48}>
        {projects.map((project, index) => (
          <div key={index}>
            <Fade
              delay={index * 100}
              direction="up"
              cascade={false}
              triggerOnce={false}
            >
              <Card maxW="md" boxShadow="lg">
                <CardHeader>
                  <HiDotsHorizontal size={32} />
                  <Divider />
                  <Flex spacing="4" mt={4}>
                    <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                      <FaFolder size={24} />
                      <Box>
                        <Heading size="sm">{project.name}</Heading>
                      </Box>
                    </Flex>
                    <IconButton
                      variant="ghost"
                      colorScheme="gray"
                      aria-label="Open Project"
                      size="md"
                      icon={<ExternalLinkIcon />}
                      as="a"
                      href={project.link}
                      target="_blank"
                      rel="noreferrer"
                    />
                  </Flex>
                </CardHeader>
                <Box padding={4} display="flex" justifyContent="center" bg={bgColor}>
                  <Image
                    maxW="100%"
                    height="100px"
                    src={project.img}
                    alt="project image"
                  />
                </Box>
 
                <CardBody>
                  <Text
                    letterSpacing={"-.0.001rem"}
                    lineHeight={"-.0.001rem"}
                    fontSize={{ base: "xs", md: "s", lg: "md" }}
                  >
                    {project.desc.length > 180
                      ? project.desc.slice(0, 180) + "..."
                      : project.desc}
                  </Text>
                </CardBody>
              </Card>
            </Fade>
          </div>
        ))}
      </ChakraCarousel>
    </Container>
  );
};

export default Project;
