import {
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  ListItem,
  Link,
  Text,
  UnorderedList,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { Bio } from "../data/personal";
import { fetchExperiences } from '../services/apiconfig';


const Experiences = () => {
  const { colorMode } = useColorMode();
  const color = useColorModeValue("#574ff8", "#eff84f")
  
  const [activeJobIndex, setActiveJobIndex] = useState(0);
  const handleJobChange = (index) => {
    setActiveJobIndex(index);
  };
  
  const getColorForIndex = (index) => {
    if (activeJobIndex === index) {
      return colorMode === "light" ? "#574ff8 " : "#eff84f";
    } else {
      return "inherit";
    }
  };
  const getFontWeightForIndex = (index) => {
    return activeJobIndex === index ? "bold" : "normal";
  };

  const [experiences, setExperiences] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getExperiences = async () => {
      try {
        const data = await fetchExperiences();
         // Ubah objek data menjadi array
         const experiencesArray = Object.keys(data).map(key => data[key]);
         setExperiences(experiencesArray);
         setActiveJobIndex(0); // Misalnya, set index aktif ke 0
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getExperiences();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Container
    id="experiences"
      maxW="8xl"
      paddingTop={{ base: "7", md: "20", lg: "20" }}
      paddingLeft={{ base: "7", md: "20", lg: "20" }}
      paddingRight={{ base: "7", md: "20", lg: "20" }}
      paddingBottom={{ base: "28" }}
    >
      <Fade direction="down" triggerOnce>
        <Box mt={{ base: "38", md: "28", lg: "10" }} textAlign="center">
          <Heading
            as="h1"
            letterSpacing={"-.0.001rem"}
            lineHeight={"-.0.001rem"}
            fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
          >
            ~/My Experience
          </Heading>
          <Text
            letterSpacing={"-.0.001rem"}
            lineHeight={"-.0.001rem"}
            fontSize={{ base: "md", md: "lg", lg: "xl" }}
          >
            My last experience that I have completed
          </Text>
        </Box>
      </Fade>
      <Box mt={10}>
        <Grid templateColumns={{ base: "1fr", md: "2fr 3fr" }} gap={2}>
          <Fade>
            <GridItem>
              {Object.entries(experiences).map(([index, experience]) => (
                <Box
                  textAlign={{ base: "left", md: "right" }}
                  key={index}
                  onClick={() => handleJobChange(index)}
                  cursor="pointer"
                  p={2}
                  borderRadius="md"
                  mb={2}
                  _hover={{
                    color: getColorForIndex(index),
                    opacity: 0.8,
                    transition: "opacity 0.1s ease",
                  }}
                >
                  <Text
                    letterSpacing={"-.0.001rem"}
                    lineHeight={"-.0.001rem"}
                    fontSize={{ base: "s", md: "md", lg: "lg" }}
                    color={getColorForIndex(index)}
                    fontWeight={getFontWeightForIndex(index)}
                  >
                    {experience.position}
                    <br />
                    {experience.date}
                  </Text>
                </Box>
              ))}
            </GridItem>
          </Fade>

          <GridItem>
            {activeJobIndex !== null  && (
              <Fade
                key={activeJobIndex}
                delay={50}
                triggerOnce={false}
              >
                <Box p={2} height="100%">
                  <Heading
                    letterSpacing={"-.0.001rem"}
                    lineHeight={"-.0.001rem"}
                    fontSize={{ base: "s", md: "md", lg: "lg" }}
                  >
                    {experiences[activeJobIndex]?.company}
                  </Heading>
                  <UnorderedList>
                    {experiences[activeJobIndex]?.desc?.map((desc, index) => (
                      <ListItem
                        letterSpacing={"-.0.001rem"}
                        lineHeight={"-.0.001rem"}
                        fontSize={{ base: "s", md: "md", lg: "lg" }}
                        key={index}
                      >
                        {desc}
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Fade>
            )}
          </GridItem>
        </Grid>
      </Box>
      <Fade direction="up" triggerOnce>
        <Box mt={10} textAlign="center">
          <Text
            letterSpacing={"-.0.001rem"}
            lineHeight={"-.0.001rem"}
            fontSize={{ base: "s", md: "md", lg: "lg" }}
          >
            You can click{" "}
            <Link
              fontWeight="bold"
              target="blank"
              color={color}
              href={Bio.socialLinks.resume}
            >
              here
            </Link>{" "}
            to check my cv, thank you
          </Text>
        </Box>
      </Fade>
    </Container>
  );
};

export default Experiences;
