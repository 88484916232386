import React from 'react';
import { Box, Text, Container, Flex, Heading, Image, Button, HStack } from '@chakra-ui/react';
import { Fade } from 'react-awesome-reveal';
import photo from '../assets/images/photo.png';
import {Bio} from '../data/personal';
import Typewriter from 'typewriter-effect';
import styled ,{ ThemeProvider }from 'styled-components';
import {socialLinks} from '../components/linkbar/linkbar'
import {useColorMode} from '@chakra-ui/react';




export const TextLoop = styled.div`
  font-weight: 500;
  font-size: 27px;
  gap: 4px;
  display : flex;
  line-height: 48px;
  @media (max-width: 960px) {
    text-align: center;
  }

    @media (max-width: 767px) {
    text-align: center;
    justify-content: center;
  }

  @media (max-width: 640px) {
    font-size: 22px;
    line-height: 48px;
    margin-bottom: 16px;
    text-align: center;
    justify-content: center;
  }
`;

export const Type = styled.div`
  color: #854CE6;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  transition: transform 0.3s ease, background-color 0.3s ease;
  &:hover {
    background-color: darkblue; /* Change this to your desired hover color */
    transform: scale(1.1);
  }
`;

export const Item = styled.div`
  display: inline-block;
  margin: 10px;
  border-radius: 50%;

  .social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 2px solid;
    border-radius: 20%;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    border-color: ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.icon};

    &:hover {
      background-color: ${({ theme }) => theme.colors.hoverBackground};
      color: ${({ theme }) => theme.colors.hoverIcon};
      border-color: ${({ theme }) => theme.colors.hoverBorder};
    }
  }
`
const Hero = (props) => {
  const { colorMode } = useColorMode();
  const theme = {
    colors: {
      border: colorMode === 'dark' ? 'white' : 'dark',
      icon: colorMode === 'dark' ? 'white' : 'dark',
      hoverBackground: colorMode === 'dark' ? 'teal' : 'teal',
      hoverIcon: colorMode === 'dark' ? 'white' : 'white',
      hoverBorder: colorMode === 'dark' ? 'teal' : 'teal'
    }
  };
    
    return ( 
    <ThemeProvider theme={theme}>

          <Container id="about"  maxW="7xl" paddingTop={{ base: "7", md: "56", lg: "40" }} paddingLeft={{ base: "7", md: "20", lg: "20" }} paddingRight={{ base: "7", md: "20", lg: "20" }} paddingBottom={{ base: "20" }} minHeight="90vh">

          <Flex  
            direction={{ base: "column", md: "column", lg:"row" }}
            alignItems={{ base: "center", md: "center" }}
            mt={{ base: "38", md: "28", lg: "44" }}>
              <Fade>                
              <Box flex="1" mt={{ base: "38", md: "18", lg: "0" }} display="flex" alignItems="center" justifyContent="center" paddingX={8}>
                  
                  <Image
                      src={photo}
                      alt="Profile Picture"
                      maxW="100%"
                      height={{base:"200px" , md:"250px", lg:"300px"}}
                  />
              </Box>
              </Fade>
              <Box flex="2" mt={{ base: "38", md: "18", lg: "0" }} textAlign={{ base: "center", md: "left"}} >
                  <Fade direction='down' triggerOnce={false}>
                  <Heading as="h1" letterSpacing={"-.0.001rem"} lineHeight={"-.0.001rem"} fontSize={{ base: "3xl", md: "3xl", lg: "4xl" }}>
                      Hola, {Bio.name} Here. 
                  </Heading>
                  <TextLoop>
                          I'm a {" { "}
                          <Type>
                              <Typewriter
                                  options={{
                                  strings: Bio.roles,
                                  autoStart: true,
                                  loop: true,
                              }}/>
                          </Type>
                          {" } "}    
                  </TextLoop>
                  </Fade>
                  <Fade >
                    <Text letterSpacing={"-.0.001rem"} lineHeight={"-.0.001rem"} fontSize={{ base: "md", md: "lg", lg: "xl" }}>{Bio.desc}</Text>
                  </Fade>
                  <Fade direction='up' triggerOnce={false}>    
                    <HStack justifyContent={{ base: "center", md: "left"}}  mt={6}>
                      {socialLinks.map((link,index) => (
                        <Item>
                          <Fade delay={index * 200} triggerOnce={true}>
                            <a
                              href={link.href}
                              target={link.target}
                              rel={link.rel}
                              className="social-icon"
                            >
                              <link.icon className="icon" size={32}/>
                            </a>
                          </Fade>
                        </Item>
                      ))}
                    </HStack>
                  </Fade>
              </Box>
              
          </Flex>

      </Container>
    </ThemeProvider>

    );
}

export default Hero