import React from 'react'
import styled from 'styled-components'
import { skills } from'../data/personal'
import { Box, Flex, Heading, Text, Container,Card, CardHeader, CardBody, } from "@chakra-ui/react";
import { Fade } from 'react-awesome-reveal';

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 500px;
  overflow: hidden; 
  height: 350px;
`

const SkillList = styled.div`
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
  
`

const SkillItem = styled.div`
  font-size: 16px;
  font-weight: 400;
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.2px solid ;
  gap: 8px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Transisi untuk transform dan box-shadow */
  cursor: pointer;
  z-index: 1;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 12px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    padding: 6px 12px;
  }
    &:hover {
    transform: scale(1.5); /* Ubah ukuran saat dihover */
    box-shadow: 0px 0px 15px rgb(0, 128, 128); /* Efek bayangan untuk menonjolkan */
    
  }
`

const SkillImage = styled.img`
  width: 24px;
  height: 24px;
`

const Skills = (props) => {
  return (
    <div id="skills">

   <Container  maxW="7xl" paddingTop={{ base: "7", md: "20", lg: "20" }} paddingLeft={{ base: "7", md: "20", lg: "20" }} paddingRight={{ base: "7", md: "20", lg: "20" }} paddingBottom={{ base: "20" }} minHeight="90vh">
    
    <Fade duration={500} distance="30px" fraction={0.4} bottom>
      <Box mt={{ base: "38", md: "18", lg: "10" }} textAlign="center">
      <Heading as="h1" letterSpacing={"-.0.001rem"} lineHeight={"-.0.001rem"} fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}>~/My Skills
        
      </Heading>
        <Text letterSpacing={"-.0.001rem"} lineHeight={"-.0.001rem"} fontSize={{ base: "md", md: "lg", lg: "xl" }}>Here are some skills I've been actively developing over the past two years</Text>
      </Box>
    </Fade>

      <Flex
      gap={8}
      direction={['column', 'row']} 
      justifyContent="center" 
      flexWrap="wrap" 
      mt={8} >
        {skills.map((skill,index) => (
          <div key={index}>
            <Fade
                  delay={index * 100}
                  direction="up"
                  cascade={false}
                  triggerOnce={true}
            >

            <StyledCard
            variant="outline" 
            boxShadow="0 0 0 2px rgb(0, 128, 128)"
            maxW='md' 
            textAlign="center" >
              <Fade
                    delay={index * 200}
                    direction="up"
                    cascade={false}
                    triggerOnce={true}
                  >
              <CardHeader>
                <Heading size='md'>{skill.title}</Heading>
              </CardHeader>
              <CardBody >
                <SkillList>
                  {skill.skills.map((item) => (
                    <SkillItem >
                      <SkillImage src={item.image}/>
                      {item.name}
                    </SkillItem>
                  ))}
                </SkillList>
              </CardBody>
              </Fade>
            </StyledCard>
            </Fade>

          </div>
        ))}
         
      </Flex>
    </Container>
    </div>
                                
  )
}

export default Skills