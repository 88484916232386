import axios from 'axios'

const BASE_URL = 'https://rino-portofolio-default-rtdb.firebaseio.com/experiences.json'
const AUTH_TOKEN = 'SpZOdsS6QgzvqzZZeqsGk4hETv6OWuBxVW7366Vk'

export const fetchExperiences = async () => {
  try {
    const response = await axios.get(`${BASE_URL}?auth=${AUTH_TOKEN}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching experiences:', error);
    throw error;
  }
};