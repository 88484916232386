import styled from 'styled-components';
import Hero from './pages/hero';
import Skills from './pages/skills';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Project from './pages/project';
import { BrowserRouter } from 'react-router-dom';
import Experiences from './pages/experiences';

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(0, 128, 128, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(208, 254, 29, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 98%, 0 100%);
}
`

function App() {
  return (
    <BrowserRouter>
      <Header/>
        <Hero/>
        <Wrapper>
          <Skills/>
          <Experiences/>
        </Wrapper>
        <Project/>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
